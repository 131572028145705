import React, {Suspense} from 'react';
import {Switch} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

import ProtectedRoutes from './ProtectedRoutes';

import Loader from '../ui-component/extended/Loader/Loader';

import AuthenticationRoutes from './AuthenticationRoutes';
import { Toaster } from 'react-hot-toast';

const Routes = () => {

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    success: {
                    style: {
                        color: '#5cb85c',
                    },
                    },
                    error: {
                        style: {
                            color: '#d9534f',
                        },
                    },
            
                }}
            />
                <Switch>
                    <>
                        {/*<Route path="*" render={() => <h1 style={{
                            textAlign: "center"
                        }}>404 Not Found</h1>} /> */}

                        {/* Routes for authetication pages */}
            
                        <AuthenticationRoutes />


                        {/* Routes for main layouts */}
                        <ProtectedRoutes />

                        
                    </>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
