import toast from 'react-hot-toast';

const hotToast = {
    success: (message, callback = null) => {
        toast.success(message);
        if (callback) {
            setTimeout(() => {
                callback();
            }, 1000);
            callback();
        }
    },
    error: (message, callback = null) => {
        toast.error(message);
        if (callback) {
            setTimeout(() => {
                callback();
            }, 1000);
            callback();
        }
    },
    remove: () => {
        setTimeout(() => {
            toast.dismiss();
        }, 2000);
    }
}

export default hotToast;