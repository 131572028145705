import React from 'react';
import { lazily } from 'react-lazily';
import { useSelector } from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import MinimalLayout from './../layout/MinimalLayout';
import { RoutePaths } from './RoutePaths';

const { HomePage, FAQPage, HowItWorksPage } = lazily(
    () => import("../views/pages")
);

const {  Login, Register, ForgotPassword, ResetPassword, AuthVerifyOTP, AuthCreatePIN } = lazily(
    () => import("../views/authentication")
);


const AuthenticationRoutes = () => {
    const location = useLocation();
    const token = useSelector(state => state.account.token);

    return (
        <Route
            path={[
                RoutePaths.login,
                RoutePaths.register,
                RoutePaths.verify_otp,
                RoutePaths.create_pin,
                RoutePaths.forgotPassword,
                RoutePaths.resetPassword,
                RoutePaths.pages.home,
                RoutePaths.pages.faq,
                RoutePaths.pages.how_it_works
            ]}

            render = {
                props => {
                    if (!token) {
                        return(
                            <MinimalLayout>
                                <Switch location={location} key={location.pathname}>
                                    <Route exact path={RoutePaths.login} component={Login} />
                                    <Route exact path={RoutePaths.register} component={Register} />
                                    <Route exact path={RoutePaths.verify_otp} component={AuthVerifyOTP} />
                                    <Route exact path={RoutePaths.create_pin} component={AuthCreatePIN} />
                                    <Route exact path={RoutePaths.forgotPassword} component={ForgotPassword} />
                                    <Route exact path={RoutePaths.resetPassword} component={ResetPassword} />
                                    <Route exact path={RoutePaths.pages.home} component={HomePage} />
                                    <Route exact path={RoutePaths.pages.faq} component={FAQPage} />
                                    <Route exact path={RoutePaths.pages.how_it_works} component={HowItWorksPage} />
                                </Switch>
                            </MinimalLayout>
                        );
                    }
                    else {
                        return <Redirect to={RoutePaths.dashboard} from="/" />
                    }
                }
            }
        >
        </Route>
    );
};

export default AuthenticationRoutes;
