import React, { useRef, useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';

import { Box, Button, Card, CardContent,  Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import ReactToPrint from "react-to-print";

import { useHistory, useLocation } from 'react-router';

import { theme as customTheme} from '../../../constants';
import { ArrowBack, PrintOutlined } from '@material-ui/icons';
import Money from '../../../utils/money';
import VTUIcon from '../../../assets/images/icons/vtu.png';
import FadeIn from 'react-fade-in';
import { IconPhone } from '@tabler/icons';

const useStyles = makeStyles(theme => ({
    card: {
        padding: "0rem"
    },
    viewLink: {
        background: "rgba(34, 176, 46, 0.1)",
        color: "#22B02E",
        display: "inline-flex",
        width: "60px",
        height: "30px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        "&:hover": {
            background: "rgba(34, 176, 46, 0.2)",
            textDecoration: "none",
        }
    },
    label: {
        fontSize: "12px",
        lineHeight: "16.37px",
        fontWeight: "400"
    },
    value: {
        fontSize: "14px",
        lineHeight: "16.37px",
        fontWeight: "400"
    },
    success: {
        backgroundColor: "rgba(34, 176, 46, 0.1)",
        color: "#22B02E",
        borderRadius: "8px",
        display: "inline-block",
        padding: "0.5rem",
        fontSize: "12px"
    },
    failed: {
        backgroundColor: "rgba(197, 41, 42, 0.1)",
        color: "#C5292A",
        borderRadius: "8px",
        display: "inline-block",
        padding: "0.5rem",
        fontSize: "12px"
    },
    pending: {
        backgroundColor: "rgba(255, 165, 0, 0.1)",
        color: "#FFA500",
        borderRadius: "8px",
        display: "inline-block",
        padding: "0.5rem",
        fontSize: "12px"
    },
    p1: {
        padding: "2px"
    },
    p2: {
        padding: "4px"
    },
    cardBd: {
        border: "2px solid #072247"
    },
    rCard: {
        width: "220px",
        height: "auto",
        border: "2px dashed #072247",
        padding: "4px",
        [theme.breakpoints.down('sm')]: {
            width: "180px"
        },
    },
    
    cardButton: {
        backgroundColor: "#072247",
        padding: "2px 8px",
        display: "inline-block",
        border: "none",
        "& > span": {
            color: "#fff"
        }
    },
    cardPin: {
        color: "#072247",
        fontSize: "14px"
    },
    rCardRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    flexGap: {
        display: "inline-flex",
        flexWrap: "wrap"
    },
    rCardCol: {
        flex: 1
    },
    textRight: {
        textAlign: "right"
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    flexLeft: {
        alignItems: "flex-start"
    },
    flexRight: {
        alignItems: "flex-end"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    textSm: {
        fontSize: "8px"
    },
    textMd: {
        fontSize: "12px"
    },
    textLg: {
        fontSize: "16px"
    },
    heading: {
        fontSize: "1.2rem",
        borderBottom: "2px solid #eee",
        padding: '1rem 0rem'
    }
}));




export default function Vouchers() {
    const classes = useStyles();

    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    const printComponentRef = useRef();
    const [vouchers, setVouchers] = useState(null);
    const [amount, setAmount] = useState(0);
    const [reference, setReference] = useState(null);

    const location = useLocation();


    useEffect(() => {
        setVouchers(location.state?.vouchers?? null);
        setAmount(location.state?.amount?? 0);
        setReference(location.state?.reference?? "N/A");
    }, [location]);
    
    return (
        <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center" className={classes.root}>
            <Grid item xs={12} sx={{minHeight: '80vh', height: '100%'}}>
                <Grid
                    sx={{marginTop: "8px", height: '100%', p: matchDownSM ? 0 : '0 20px'}}
                    container
                    direction="column"
                    alignItems={'center'}
                    spacing={matchDownSM ? 5 : 6}
                    justifyContent="space-between"
                >
                  
                    <Grid item xs={12} container direction="column">
                        <VouchersToPrint vouchers={vouchers} reference={reference} amount={amount} matchDownSM={matchDownSM} classes={classes}  ref = {printComponentRef} />
                        <Box mt={6} textAlign="center">
                            <Button
                                startIcon={<ArrowBack  />}
                                style={{marginRight: '1rem', color: "666666", fontWeight: '400', fontSize: '15px', fontStyle: 'normal' }}
                                onClick = {() => {
                                    history.goBack();
                                }}
                
                            >
                                Go Back
                            </Button>

                            <ReactToPrint
                                trigger={() => <Button
                                size="small"
                                    disableElevation
                                    variant="outlined"
                                    className={classes.bill}
                                    startIcon={<PrintOutlined  />}
                                    style={{marginLeft: '1rem'}}
                                >
                                Print Receipt
                            </Button>}
                                content={() => printComponentRef.current }
                            />
                        </Box>
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
    );
}

const VouchersToPrint = React.forwardRef(({classes, matchDownSM, vouchers, amount, reference}, ref) => {
    
    return (
        <div className="print-source" ref={ref}>
        <FadeIn>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <div style={{marginBottom: "2rem"}}>
                                <Typography
                                    color={customTheme.dark}
                                    gutterBottom
                                    variant={"body1"}
                                    className={classes.heading}
                                >
                                    VOUCHER REFERENCE: <span style={{fontWeight: "700"}}>{reference}</span>
                                </Typography>
                            </div>

                            <div className={classes.flexGap}>
                                {
                                    vouchers?.map((item, index) => (
                                        <div key={index}>
                                            <RechargeCard classes={classes} amount={amount} cardPin={item?.pin} cardSerial={item.serial} cardDate={item.created_at} />
                                        </div>
                                    ))
                                }
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </FadeIn>
        </div>
    );
});


const RechargeCard = ({classes, amount, cardPin, cardSerial, cardDate}) => {
    return(
        <div className={classes.rCard}>
            <div className={classes.rCardRow}>
                <div className={classes.rCardCol + " " + classes.textCenter}>
                    <span className={classes.textSm}>DIGITAL OUTPUT VOUCHER</span>
                </div>
            </div>

            <div className={classes.rCardRow}>
                <div className={classes.rCardCol + " " + classes.textLeft}>
                    <button className={classes.cardButton}>
                        <span className={classes.textSm}>VTU: </span> <span className={classes.textSm}>{Money.formatAmount(amount?? 0)}</span>
                    </button>
                </div>
                <div className={classes.rCardCol + " " + classes.textRight}>
                    <img src={VTUIcon} alt = "VTU" />
                </div>
            </div>

            <div className={classes.rCardRow}>
                <div className={classes.rCardCol + " " + classes.textLeft}>
                    <span className={classes.textSm}>MTN, Airtel, 9Mobile, GLO</span>
                </div>
            </div>

            <div className={classes.rCardRow}>
                <div className={classes.rCardCol + " " + classes.textCenter}>
                    <span className={classes.cardPin}>{cardPin?? 'xxxxxxxxxxxxxxxxxxxxx'}</span>
                </div>
            </div>

            <div className={classes.rCardRow}>
                <div className={classes.rCardCol + " " + classes.textCenter}>
                    <span className={classes.textSm}>To Load: *174*PIN#</span>
                </div>
            </div>

            <div className={classes.rCardRow}>
                <div className={classes.rCardCol + " " + classes.textLeft}>
                    <span className={classes.textSm}>Serial: {cardSerial?? 'xxxx'}</span>
                </div>

                <div className={classes.rCardCol + " " + classes.textRight}>
                    <IconPhone size={10} /> <span className={classes.textSm}> 08091142200</span>
                </div>
            </div>
        </div>
    );
}
