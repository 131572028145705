import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Divider, Drawer, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';

import {theme as customTheme} from '../../../constants';

import MenuList from './MenuList';

import LogoSection from '../LogoSection';

import {drawerWidth} from './../../../store/constant';
import UserProfileCard from '../../../ui-component/cards/UserProfileCard';

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        },
        zIndex: 0
    },
    drawerPaper: {
        width: drawerWidth,
        background: customTheme.light,
        position: "relative",
        color: customTheme.dark,
        borderRight: 'none',
        borderRadius: "24px",
        [theme.breakpoints.up('md')]: {
            top: '20px',
            marginLeft: "20px"
        }
    },
    ScrollHeight: {
        height: 'calc(100vh - 88px)',
        paddingLeft: '16px',
        paddingRight: '16px',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 56px)'
        }
    },
    boxContainer: {
        display: 'flex',
        padding: '16px',
        justifyContent: "center"
    },
    menuDivider: {
        marginTop: '2px',
        marginBottom: '10px',
        marginLeft: "20px",
        marginRight: "20px"
    }
}));

const Sidebar = (props) => {
    const {drawerOpen, drawerToggle, window} = props;
    const classes = useStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <React.Fragment>
            <div className={classes.boxContainer}>
                <LogoSection />
            </div>
            <Divider className={classes.menuDivider} />
            <PerfectScrollbar className={classes.ScrollHeight}>
                <MenuList />
                <UserProfileCard/>
            </PerfectScrollbar>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{keepMounted: true}}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </nav>
    );
};

export default Sidebar;
