import React from 'react';
import {Link} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

import {app} from '../../../config';

import logo from './../../../assets/images/logo.svg';
import { FormattedMessage } from 'react-intl';

const LogoSection = ({linkStyle = {}, imageStyle = {}}) => {
    return (
        <React.Fragment>
            <Link style={linkStyle} component={RouterLink} to={app.defaultPath}>
                <img style={imageStyle} src={logo} alt={<FormattedMessage id="digital-output" />} width="150" />
            </Link>
        </React.Fragment>
    );
};

export default LogoSection;
