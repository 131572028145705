import {TRANSACTION_COMPLETED, TRANSACTION_INITIALIZE, TRANSACTION_RESET} from './actions';

const initialState = {
    completed: false,
    transactionStatus: null,
    transactionReference: null,
    nextRoute: null,
    endpoint: null,
    initialRoute: null,
    currency: 'NGN',
    currency_symbol: '₦',
    charges: 0,
    data: {}
}
const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTION_INITIALIZE: {
            return {
                ...state,
                ...action.payload
            };
        }
        case TRANSACTION_COMPLETED: {
            return {
                ...state,
                ...action.payload
            };
        }
        case TRANSACTION_RESET: {
            return initialState
        }
        default: {
            return state;
        }
    }
};

export default transactionReducer;
