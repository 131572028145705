
import { RoutePaths } from "../routes/RoutePaths"
import { RESET_APP } from "../store/actions";
import hotToast from "../utils/hotToast";

export const logout = (navigation, dispatch) => {
    dispatch({type: RESET_APP});
    navigation.push(RoutePaths.login);
}

export const dispatchError = (err, dispatch, navigation) => {
    if (typeof err === 'object') {
        const status = err?.status?? 'error';
        if (status.toLowerCase() === 'expired') {
            hotToast.error(err?.message?? "Session expired! Please login again");
            logout(navigation, dispatch);
        }
        else {
            hotToast.error(err?.message?? 'Oops! Something went wrong.');
        }
    }
    else {
        hotToast.error("Please check your network connection");
    }

    /*
    (res) => {
            hotToast.error(res.message);
                    hotToast.error("Session expired! Please login again.");
                    logout(history, dispatch);
                }
            */
}

export const canRefreshByTime = (dateStr, multiple = 2) => {
    if (!dateStr) {
        return false
    }

    const startMinutes = new Date(dateStr).getMinutes();
    const currentMinutes = new Date().getMinutes();

    const minutes = Math.abs(startMinutes - currentMinutes);

    return minutes%multiple === 0;
}
