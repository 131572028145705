
const baseUrls = {
    transactions: '/transactions',
    transaction: '/transaction',
    bill: '/bill',
    account: '/account',
    pages: ''
}
const RoutePaths = {
    dashboard : '/dashboard',
    transactions: '/transactions',
    referrals: '/referrals',
    bill: baseUrls.bill,
    deposit: baseUrls.account + '/deposit',
    verifyPaymentOTP: baseUrls.account + '/payment/otp',
    withdraw: baseUrls.account + '/withdraw',
    wallet_history: baseUrls.account + '/wallet-history',
    account_upgrade: baseUrls.account + '/upgrade',
    account_password: baseUrls.account + '/password',
    account_pin: baseUrls.account + '/pin',
    account: baseUrls.account,
    profile: '/profile',
    login: '/login',
    register: '/register',
    verify_otp: '/otp/verification',
    create_pin: '/pin',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    airtime: baseUrls.bill + '/airtime',
    airtime_select: baseUrls.bill + '/airtime/products',
    internet: baseUrls.bill + '/internet',
    cabletv: baseUrls.bill + '/cabletv',
    cabletv_select: baseUrls.bill + '/cabletv/products',
    send_money: baseUrls.bill + '/transfer-money',
    power: baseUrls.bill + '/buy-power',
    result_checker: baseUrls.bill + '/result/check',
    examination: baseUrls.bill + '/examination',
    examination_select: baseUrls.bill + '/examination/products',
    vouchers: baseUrls.bill + '/vouchers/purchase',
    vouchers_print: baseUrls.bill + '/vouchers/print',
    txn_confirmation: baseUrls.transaction + '/confirm',
    txn_card_confirmation: baseUrls.transaction + '/confirm-with-card',
    txn_summary: baseUrls.transaction + '/summary',
    txn_completed: baseUrls.transaction + '/completed',
    pages: {
        home: baseUrls.pages + '/',
        faq: baseUrls.pages + '/faq',
        how_it_works: baseUrls.pages + '/how-it-works',
        become_a_vendor: baseUrls.pages + '/become-a-vendor',
    }
}

export {RoutePaths, baseUrls};