// globalStyles.js
import { createGlobalStyle } from 'styled-components';
 
const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }

  .menu-toggler {
    color: #2196f3;
    padding: 12px;
    display: flex;
    alignItems: center;
    justifyContent: center;
    border-radius: 8px;
    transition: background-color 0.5s ease;
    margin-top: -8px;
    :hover {
      background-color: #eee;
    }
  }
`;
 
export default GlobalStyles;