import React, {useState} from 'react';
import clsx from 'clsx';
import {Formik} from 'formik';
import {Box, Button, Grid, makeStyles, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {rgba, theme as customTheme} from '../../constants';

import ClipLoader from 'react-spinners/ClipLoader';
import {postData} from '../../api/httpClient';
import {endpoints} from '../../api/endpoints';
import hotToast from '../../utils/hotToast';
import {useDispatch, useSelector} from 'react-redux';

import OtpInput from 'react-otp-input';
import { dispatchError } from '../../shared/functions';
import { ACCOUNT_REFRESH } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {},

    title: {
        color: theme.palette.grey[600]
    },
    login: {
        backgroundColor: customTheme.darkBlue,
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: rgba(34, 51, 68, 0.9)
        }
    },

    loader: {
        borderColor: customTheme.white,
        display: 'block',
        margin: '0 auto'
    },
    resendOTPButton: {
        background: customTheme.success,
        color: customTheme.green,
        boxShadow: 'none',
        '&:hover': {
            background: customTheme.success,
            boxShadow: 'none'
        }
    }
}));

const CreatePin = ({className, ...rest}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const account = useSelector((state) => state.account);
    const [pin, setPIN] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    pin,
                    submit: null
                }}
                validate={() => {}}
                onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                    values.pin = pin;
                    values.recipient = account.user.phone_number;

                    setLoading(true);
                    setSubmitting(false);

                    await postData(
                        endpoints.create_pin,
                        values,
                        (res) => {
                            setStatus({success: true});
                            hotToast.success(res.message);
                            setLoading(false);
                            dispatch({type: ACCOUNT_REFRESH, payload: {refresh: true}});
                        },
                        (res) => {
                            setStatus({success: false});
                            setLoading(false);
                            dispatchError(res, dispatch, history);
                        }
                    );
                }}
            >
                {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography color={customTheme.darkBlue} gutterBottom variant={'h2'}>
                                    Create Transaction PIN
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" fontSize="16px">
                                {' '}
                                This PIN would be used to verify all your transaction. Think of it as an extra layer of security! 😉
                            </Typography>
                        </Grid>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Box mt={2} mb={4}>
                                    <OtpInput
                                        containerStyle={{
                                            justifyContent: 'center'
                                        }}
                                        value={pin}
                                        onChange={(pin) => setPIN(pin)}
                                        numInputs={4}
                                        separator={<span style={{padding: '10px'}}></span>}
                                        inputStyle={{
                                            border: '1px solid #19C0FF',
                                            width: '46px',
                                            height: '46px',
                                            color: 'black',
                                            fontSize: '40px',
                                            caretColor: 'transparent'
                                        }}
                                        isInputSecure={true}
                                    />
                                </Box>

                                <Box mt={8} minWidth="200px">
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className={classes.login}
                                    >
                                        {!loading && 'Create PIN'}{' '}
                                        <ClipLoader color={'#FFFFFF'} loading={loading} css={classes.loader} size={30} />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default CreatePin;
