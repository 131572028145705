import {FUNDING_INVOICE_INITIALIZE, FUNDING_INVOICE_RETRIEVE, FUNDING_INVOICE_RESET} from './actions';

const initialState = {
    client_id: null,
    reference: null,
    unit_cost: null,
    quantity: null,
    cardNumber: null,
    cvc: null,
    expiryDate: null
}
const walletFundingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FUNDING_INVOICE_INITIALIZE: {
            const {client_id, reference, unit_cost, quantity, cardNumber, cvc, expiryDate} = action.payload;
            return {
                ...state,
                client_id,
                reference,
                unit_cost,
                quantity,
                cardNumber,
                cvc,
                expiryDate
            };
        }
        case FUNDING_INVOICE_RETRIEVE: {
            return state
        }
        case FUNDING_INVOICE_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default walletFundingReducer;
