import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import {Formik} from 'formik';
//import {Link as RouterLink} from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from '@material-ui/core';

import ClipLoader from "react-spinners/ClipLoader";

import { rgba, theme as customTheme } from '../../constants';
import { useDispatch } from 'react-redux';
import { endpoints } from '../../api/endpoints';
import { BillTypes } from '../../constants/billTypes';
import { useHistory } from 'react-router';
import { getData, postData } from '../../api/httpClient';
import { dispatchError } from '../../shared/functions';
import hotToast from '../../utils/hotToast';

import { ACCOUNT_REFRESH } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    margin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    title: {
        color: theme.palette.grey[600]
    },
    bill: {
        backgroundColor: customTheme.darkBlue,
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: rgba(34, 51, 68, 0.9)
        }
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "220px",
        '& > label': {
            top: '23px',
            left: 0,
            color: theme.palette.grey[500],
            '&[data-shrink="false"]': {
                top: '5px'
            }
        },
        '& > div > input': {
            padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    },
    formSelect: {
        "& > div": {
            paddingTop: "1rem"
        }
    },
    startAdornment: {
        color: theme.palette.grey[500],
        marginTop: '18px',
        width: 'auto'
    },
    link: {
        color: customTheme.skyBlue,
        textDecoration: "none"
    },
    error: {
        color: customTheme.textDanger
    }
}));

const BillForm = (props, {className, ...rest}) => {
    const [loading, setLoading] = useState(false);
    const [loadingBanks, setLoadingBanks] = useState(false);
    const classes = useStyles();
 
    const dispatch = useDispatch();
    const [banks, setBanks] = useState(null);

    const [customerLoading, setAccountNameLoading] = useState(false);
    const [accountName, setAccountName] = useState(null);
    const [resolveError, setResolveError] = useState(false);
    let timeoutId;

    const history = useHistory();

    const fetchBanks = useCallback(async ()  => {
        setLoadingBanks(true);

        await getData(endpoints.fetchBanks, {}, (res) => {//Success
            setBanks(res?.data?.length > 0? res?.data : null);
            setLoadingBanks(false);
        }, (res) => { //Error
            setBanks(null);
            setLoadingBanks(false);
            dispatchError(res, dispatch, history);
        });

    }, [dispatch, history]);

    useEffect(() => {
        //Fetch banks
        fetchBanks();
    }, [fetchBanks]);


    const getBankName = (bank_code) => {
        if (!banks) return "";

        for (let i  = 0; i < banks.length; i++) {
            if (banks[i].code === bank_code) {
                return banks[i].name;
            }
        }
    }

    const resolveCustomer = (values) => {
        clearTimeout(timeoutId);
        
        timeoutId = setTimeout(async () => {
            const url = endpoints.resolveCustomer;

            const data = {
                type: BillTypes.resolve_bank_transfer_customer, 
                account: values.account_number, 
                bank_code: values.bank_code,
                country: 'NG'
            };

            setResolveError(false);
            setAccountNameLoading(true);
            setAccountName(null);
            
            await postData(url, data, (res) => {//Success
                setAccountName(res?.data?.name);
                setAccountNameLoading(false);
            }, (res) => { //Error
                setAccountName(res.message);
                setAccountNameLoading(false);
                setResolveError(true);
                dispatchError(res, dispatch, history);   
            });
        }, 1000);
    }

    return (
        <React.Fragment>
            
            <Formik
                initialValues={{
                    bank_name: '',
                    bank_code: '',
                    account_number: '',
                    bvn: '',
                    account_name: '',
                    remark: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    bank_code: Yup.string().required('Bank name is required'),
                    account_number: Yup.string().required('Account number is required'),
                    bvn: Yup.string().max(45).required('BVN is required'),
                    account_name: Yup.string()
                })}
                onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                    setLoading(true);
                    values.bank = getBankName(values.bank_code);

                    const data = {
                        bank : getBankName(values.bank_code),
                        account_number: values.account_number,
                        account_name : accountName,
                        bvn: values.bvn
                    }

                    await postData(endpoints.addBankDetails, data, (res) => {//Success
                        setLoading(false);
                        hotToast.success(res?.message);
                        dispatch({type: ACCOUNT_REFRESH, payload: {refresh: true}});
                    }, (res) => { //Error
                        setLoading(false);
                        dispatchError(res, dispatch, history);   
                    });
                }}
            >
                {({errors, handleBlur, handleSubmit, handleChange, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
                    
                    <Grid item container direction="column">
                            <Grid item>
                                <Typography color={customTheme.darkBlue} gutterBottom variant={'h2'}>
                                    Add Bank Account
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" fontSize="16px">
                                {' '}
                                Your withdrawals will be sent to the account details you provided here. Also, we will be creating
                                 <span style={{color: customTheme.black}}>a virtual account number automatically</span> as an alternative to fund your wallet.
                            </Typography>
                        </Grid>
                    <br />
                    <FormControl 
                        fullWidth
                        error={Boolean(touched.bank_code && errors.bank_code)}
                        className={classes.formSelect + " " + classes.formControl}
                    >
                        <InputLabel htmlFor="bank_code-label">Select Bank Name</InputLabel>
                        <Select
                            labelId="bank_code-label"
                            id="bank_code"
                            name="bank_code"
                            value={loadingBanks? 'loading' : values.bank_code}
                            onChange={handleChange}
                        >
                            {loadingBanks && <MenuItem value='loading'><ClipLoader color={"#000000"} loading={loadingBanks} css={classes.loader} size={15} /></MenuItem>}
                                
                            {
                                banks?.map((item, index) => (
                                    <MenuItem key={index} value={item.code}>{item.name}</MenuItem>
                                ))
                            }

                                {!loadingBanks && !banks && <MenuItem value=''>No bank found</MenuItem>}
                        </Select>

                        {touched.bank_code && errors.bank_code && (
                            <FormHelperText error id="bank_code">
                                {' '}
                                {errors.bank_code}{' '}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl 
                        fullWidth
                        error={Boolean(touched.account_number && errors.account_number)}
                        className={classes.formSelect + " " + classes.formControl}
                    >
                        <InputLabel htmlFor="account_number-label">Account Number</InputLabel>
                           
                        <OutlinedInput
                            id="account_number"
                            type="text"
                            value={values.account_number}
                            name="account_number"
                            onBlur={handleBlur}
                            label='account_number'
                            disabled={values.bank_code? false : true}    
                            onChange={(e) => {
                                handleChange('account_number')(e);
                                if (e.target.value.length >= 8)
                                    values.account_number = e.target.value;
                                    resolveCustomer(values);
                            }}
                        />
            
                            {customerLoading && <ClipLoader color={"#000000"} loading={customerLoading} css={classes.loader} size={15} />}
                    
                            {touched.account_number && errors.account_number && (
                                <FormHelperText error id="account_number">
                                    {' '}
                                    {errors.account_number}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl 
                            fullWidth
                            error={Boolean(touched.account_name && errors.account_name)}
                            className={classes.formSelect + " " + classes.formControl}
                        
                        >
                            <InputLabel htmlFor="account_name-label">Account Name</InputLabel>
                           
                            <OutlinedInput
                                id="account_name"
                                type="text"
                                value={accountName?? values.account_name}
                                name="account_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='account_name'
                                disabled
                            />

                            {touched.account_name && errors.account_name && (
                                <FormHelperText error id="account_name">
                                    {' '}
                                    {errors.account_name}{' '}
                                    {resolveError}
                                </FormHelperText>
                            )}
                        </FormControl>
                        

                        <FormControl
                            fullWidth
                            error={Boolean(touched.bvn && errors.bvn)}
                            className={classes.formControl}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="bvn">BVN</InputLabel>
                            <OutlinedInput
                                id="bvn"
                                type="text"
                                value={values.bvn}
                                name="bvn"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Bank Verification Number'
                               
                            />
                            {touched.bvn && errors.bvn && (
                                <FormHelperText error id="bvn">
                                    {' '}
                                    {errors.bvn}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <p style={{color: customTheme.textDanger}}>Please note: all information provided here is safe and will be used solely on your account created on this platform.</p>
       
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box mt={4} mb={2}>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className={classes.bill}
                            >
                                {!loading && 'Save Bank Data'} <ClipLoader color={"#FFFFFF"} loading={loading} css={classes.loader} size={30} />
                                
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default BillForm;
