import {ACCOUNT_INITIALISE, ACCOUNT_REFRESH, LOGIN} from './actions';

const initialState = {
    token: null,
    user: null,
    isInitialised: false,
    refresh: false
}
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALISE: {
            const {user, refresh} = action.payload;
            return {
                ...state,
                isInitialised: true,
                user,
                refresh
            };
        }
        case ACCOUNT_REFRESH: {
            const {refresh} = action.payload;
            return {
                ...state,
                refresh
            }
        }
        case LOGIN: {
            const {token, user} = action.payload;
            return {
                ...state,
                token,
                user
            };
        }
        default: {
            return state;
        }
    }
};

export default accountReducer;
