

export const BillTypes = {
    buy_airtime: 'BUY_AIRTIME',
    buy_data: 'BUY_DATA',
    fetch_data_plans: 'FETCH_DATA_PLANS',
    renew_paytv: 'RENEW_PAYTV_SUBSCRIPTION',
    fetch_paytv_plans: 'FETCH_PAYTV_PLANS',
    resolve_paytv_customer: 'RESOLVE_PAYTV_CUSTOMER',
    buy_power: 'BUY_POWER',
    fetch_power_providers: 'FETCH_POWER_DISTRIBUTORS',
    resolve_power_customer: 'RESOLVE_POWER_CUSTOMER',
    buy_ussd_vouchers: 'BUY_USSD_VOUCHERS',
    fetch_ussd_vouchers: 'FETCH_USSD_VOUCHERS',
    bank_transfer: 'PERFORM_BANK_TRANSFER',
    resolve_bank_transfer_customer: 'RESOLVE_BANK_TRANSFER_CUSTOMER'
}