import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import {Formik} from 'formik';

import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    makeStyles,
    OutlinedInput
} from '@material-ui/core';

import useScriptRef from '../../../hooks/useScriptRef';
import ClipLoader from "react-spinners/ClipLoader";


import { rgba, theme as customTheme } from '../../../constants';

import hotToast from '../../../utils/hotToast';
import { postData } from '../../../api/httpClient';
import { endpoints } from '../../../api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { FUNDING_INVOICE_RESET, LOGOUT } from '../../../store/actions';


import successIcon from '../../../assets/images/success.svg';
import failedIcon from '../../../assets/images/failed.svg';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    margin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    title: {
        color: theme.palette.grey[600]
    },
    bill: {
        backgroundColor: customTheme.darkBlue,
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: rgba(34, 51, 68, 0.9)
        }
    },
   
    startAdornment: {
        color: theme.palette.grey[500],
        marginTop: '18px',
        width: 'auto'
    },
    link: {
        color: customTheme.skyBlue,
        textDecoration: "none"
    },
    otpButton: {
        backgroundColor: customTheme.primary
    }
}));

const OTPForm = ({className, payOk, setPayOk, hasError, setError, loading, setLoading, ...rest}) => {
    const classes = useStyles();
    const scriptedRef = useScriptRef();
    const [message, setMessage] = useState(null);

    const walletFunding = useSelector(state => state.walletFunding);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            
            <Formik
                initialValues={{
                    otp: null,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    otp: Yup.number().required('OTP is required')
                })}
                onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {

                    let data = {otp: values.otp, reference: walletFunding.reference};
                    let url = endpoints.verifyPaymentOTP
                    setLoading(true);

                    try {

                        if (scriptedRef.current) {
                            setStatus({success: true});
                            setSubmitting(false);
       
                            await postData(url, data, (res) => {
                                setPayOk(true);
                                setError(false);
                                setMessage(res.message);
                                dispatch({type: FUNDING_INVOICE_RESET})
                            }, (res) => {
                                setError(true);
                                setPayOk(false);
                                hotToast.error(res.message);

                                dispatch({type: FUNDING_INVOICE_RESET});                
                                if (res.status === "expired") {
                                    dispatch({type: LOGOUT});
                                }
                            });


                            setLoading(false);
                            
                        }
                    } catch (err) {
                        hotToast.error(err.message);

                        if (scriptedRef.current) {
                            setStatus({success: false});
                            setErrors({submit: err.message});
                            setSubmitting(false);
                        }

                        setLoading(false);
                    }
                }}
            >
                {({errors, handleBlur, handleSubmit, handleChange, isSubmitting, touched, values}) => (
                    <form noValidate onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>

                        {(!loading && !payOk)? 
                            <Box my={2}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.otp && errors.otp)}
                                    className={classes.formControl}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="otp">Enter OTP Code</InputLabel>
                                    <OutlinedInput
                                        id="otp"
                                        type="text"
                                        name="otp"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label='otp'
                                    

                                    />
                                    {touched.otp && errors.otp && (
                                        <FormHelperText error id="otp">
                                            {' '}
                                            {errors.otp}{' '}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                            
                            :
                            <Box textAlign="center">
                                {
                                    (payOk && !hasError) && <>
                                        <img src={successIcon} alt="Success" />
                                        <p style={{marginTop: "1.5rem", marginBottom: "1.5rem"}}>
                                            {
                                                message
                                            }
                                        </p>     
                                    </>
                                }

                                {
                                    (!loading && hasError) && <>
                                            <img src={failedIcon} alt="Transaction failed" />
                                            <p style={{marginTop: "1.5rem", marginBottom: "1.5rem"}}>
                                                {
                                                    message
                                                }
                                            </p>     
                                        </>
                                }
                                              
                            </Box>
                  
                                    
                        
                        }

                        {
                            loading && <Box textAlign="center">
                                <ClipLoader color={customTheme.textPrimary} loading={loading} css={classes.loader} />

                                <h4 style={{marginBottom: "1.5rem"}}>
                                    Processing payment...
                                </h4>  
                            </Box>
                        }

                            

                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        {!loading && !payOk && <Box mt={8} mb={2}>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className={classes.bill}
                            >
                                Verify OTP
                                {<ClipLoader color={"#FFFFFF"} loading={loading} css={classes.loader} size={30} />}
                                
                            </Button>
                        </Box>}
                    </form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default OTPForm;
