import React from 'react';
import { theme as customTheme } from '../../../../constants';
import {
    Button,
    makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../shared/functions';
import { useHistory } from 'react-router';
import hotToast from '../../../../utils/hotToast';

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        color: customTheme.textDanger,
        background: customTheme.light
    }
}));

const LogoutSection = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        hotToast.success("Logout successfully");
        logout(history, dispatch);
    }

    return (
        <React.Fragment>
            <Button className={classes.logoutButton} onClick={handleLogout}>
                <FormattedMessage id="logout" />
            </Button>
        </React.Fragment>
    );
};

export default LogoutSection;
