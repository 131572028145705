import { Modal } from "antd";
import React from "react";


const DgModal = ({visible, children, title}) => {
    return(
        <Modal
            title={title}
            visible={visible}
            footer={null}
            closable={false}
        >
            {children}
        </Modal>
    );
}

export default DgModal;
