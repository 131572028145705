import React from 'react';
import {FormattedMessage} from 'react-intl';
import { icons } from '../constants';
import { RoutePaths } from '../routes/RoutePaths';

const menuItems = {
    items: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: <FormattedMessage id="dashboard" />,
                    type: 'item',
                    url: RoutePaths.dashboard,
                    icon: icons['IconDashboard'],
                    breadcrumbs: false
                },
                {
                    id: 'transactions',
                    title: <FormattedMessage id="transactions" />,
                    type: 'item',
                    url: RoutePaths.transactions,
                    icon: icons['IconTransaction'],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'bill-payment',
            title: <FormattedMessage id="bill-payment" />,
            type: 'group',
            children: [
                {
                    id: 'airtime-topup',
                    title: <FormattedMessage id="airtime-topup" />,
                    type: 'item',
                    url: RoutePaths.airtime_select,
                    icon: icons['IconAirtimeTopup'],
                   
                },
                {
                    id: 'internet-topup',
                    title: <FormattedMessage id="internet-topup" />,
                    type: 'item',
                    url: RoutePaths.internet,
                    icon: icons['IconInternetTopup'],
                },
                {
                    id: 'send-money',
                    title: <FormattedMessage id="send-money" />,
                    type: 'item',
                    url: RoutePaths.send_money,
                    icon: icons['IconSendMoney'],
                   
                },
                {
                    id: 'cabletv',
                    title: <FormattedMessage id="cabletv" />,
                    type: 'item',
                    url: RoutePaths.cabletv_select,
                    icon: icons['IconCableTV'],
                },
                {
                    id: 'electricity',
                    title: <FormattedMessage id="electricity" />,
                    type: 'item',
                    url: RoutePaths.power,
                    icon: icons['IconElectricity'],
                },
                {
                    id: 'examinations',
                    title: <FormattedMessage id="examinations" />,
                    type: 'item',
                    url: RoutePaths.examination_select,
                    icon: icons['IconExamination'],
                   
                },
            ]
        }
    ]
};

export default menuItems;
