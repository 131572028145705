import React from 'react';
import {Box, Button, Card, CardContent, Grid, makeStyles, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {theme as customTheme} from '../../../constants';
import Money from '../../../utils/money';
import { useSelector } from 'react-redux';
import { ArrowBack, PrintOutlined, RefreshOutlined } from '@material-ui/icons';
import { camelCase, startCase } from 'lodash';
import failedIcon from '../../../assets/images/failed.svg';
import successIcon from '../../../assets/images/success.svg';
import { useHistory } from 'react-router';
import { RoutePaths } from '../../../routes/RoutePaths';
import FadeIn from 'react-fade-in';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: customTheme.transparent,
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        maxWidth: 'calc(100% + 16px)',
    },
    card: {
        margin: theme.spacing(0) + ' auto',
        background: customTheme.light,
        minWidth: '420px',
        overflow: 'visible',
        display: 'flex',
        position: 'relative',
        '& > *': {
            flexGrow: 1,
            flexBasis: '50%'
        }
    },
    content: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(3)
        } 
    },
    title: {
        color: customTheme.dark,
        textDecoration: 'none'
    },
    marginVertical: {
        marginTop: "30px",
        marginBottom: "30px"
    },
    bill: {
        fontSize: "12px",
        fontStyle: "normal",
        backgroundColor: customTheme.lightGray,
        color: customTheme.darkBlue,
        padding: '6px 20px',
        '&:hover': {
            backgroundColor: customTheme.darkBlue,
            color: customTheme.light,
            '*': {
                color: customTheme.light
            }
        }
    },
    emphasis: {
        fontWeight: '600'
    },
    success: {
        color: customTheme.textSuccess
    },
    failed: {
        color: customTheme.textDanger
    }
}));

const TransactionCompleted = ({status}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const txnReducer = useSelector(state => state.transaction);
    const history = useHistory();

    return (
        <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center" className={classes.root}>
            <Grid item xs={12} sx={{minHeight: '100vh', height: '100%'}}>
                <Grid
                    sx={{marginTop: "16px", height: '100%', p: matchDownSM ? 0 : '0 200px'}}
                    container
                    direction="column"
                    alignItems={matchDownSM ? 'center' : 'flex-start'}
                    spacing={matchDownSM ? 5 : 6}
                    justifyContent="space-between"
                >
                  
                   
                        <Card className={classes.card}>
                        <FadeIn>
                            <CardContent className={classes.content}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="column"
                                        >
                                            <Grid item md={12}>
                                            
                                               <Box mb={3} textAlign="center">
                                                    <Box>
                                                        <img src={txnReducer?.transactionStatus === "success"? successIcon:failedIcon} alt={txnReducer?.transactionStatus} />
                                                    </Box>
                                                    <Typography
                                                        color={customTheme.dark}
                                                        gutterBottom
                                                        variant="h2"
                                                        mb={1}
                                                        className={txnReducer?.transactionStatus === "success"? classes.success:classes.failed + " " + classes.heading}
                                                    >
                                                        {txnReducer?.transactionStatus === "success"? 'Transaction Successful!':'Transaction Failed'}
                                                        
                                                    </Typography>

                                                    <Typography variant='body2' mt={6}>
                                                        {startCase(camelCase(txnReducer?.data?.type))} transaction of <span className={classes.emphasis}>{Money.formatAmount(txnReducer?.data?.amount, txnReducer?.currency_symbol)}</span> {txnReducer?.data?.account && <>for <span className={classes.emphasis}>{txnReducer?.data?.account}</span><br /></>} was {txnReducer?.transactionStatus === "success"? '':'not'} successful. 
                                                    </Typography>

                                                    <Typography variant='body2' mt={4}>
                                                        Transaction reference #{txnReducer?.reference}
                                                    </Typography>
                                               </Box>

                                               <Box mt={4} mb={2} textAlign="center">
                                                    {txnReducer?.transactionStatus === "success"? (<Button
                                                        size="small"
                                                        disableElevation
                                                        variant="contained"
                                                        className={classes.bill}
                                                        startIcon={<PrintOutlined  />}
                                                        onClick={() => history.push(RoutePaths.transactions + "/" + txnReducer?.reference)}
                                                    >
                                                        Print Receipt
                                                    </Button>)
                                                    :
                                                    (<Button
                                                        size="small"
                                                        disableElevation
                                                        variant="contained"
                                                        className={classes.bill}
                                                        startIcon={<RefreshOutlined  />}
                                                        onClick={() => history.goBack()}
                                                    >
                                                        Try Again
                                                    </Button>)}
                                                </Box>

                                            </Grid>
         
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </CardContent>
                        </FadeIn>
                        </Card>

                        <Box mt={6} alignItems="center" alignSelf="center">
                            <Button
                                startIcon={<ArrowBack  />}
                                style={{ color: "666666", fontWeight: '400', fontSize: '15px', fontStyle: 'normal' }}
                                onClick = {() => {
                                    if (txnReducer?.transactionStatus === "success") {
                                        history.push(txnReducer.initialRoute);
                                    }
                                    else {
                                        history.goBack();
                                    }
                                    
                                }}
                            >
                                Go Back
                            </Button>
                        </Box>
                   
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TransactionCompleted;

