import React, { useState } from 'react';
import {Box, Button, Card, CardContent, Grid, makeStyles, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {theme as customTheme} from '../../../constants';
import OTPForm from './OTPForm';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { RoutePaths } from '../../../routes/RoutePaths';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: customTheme.transparent,
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        maxWidth: 'calc(100% + 16px)'
    },
    card: {
        margin: theme.spacing(0) + ' auto',
        background: customTheme.light,
        maxWidth: '453px',
        minWidth: '300px',
        overflow: 'visible',
        display: 'flex',
        position: 'relative',
        '& > *': {
            flexGrow: 1,
            flexBasis: '50%'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '400px'
        },
        [theme.breakpoints.down('sm')]: {
        
        }
    },
    content: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(3)
        } 
    },
    title: {
        color: customTheme.dark,
        textDecoration: 'none'
    },
    marginVertical: {
        marginTop: "30px",
        marginBottom: "30px"
    },
    bill: {
        fontSize: "21.33px",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "29.09px"
    },
    newAccount: {
        paddingLeft: "10px",
        color: customTheme.skyBlue,
        textDecoration: "none"
    }
}));

const VerifyPaymentOTP = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const location = useLocation();
    const [payOk, setPayOk] = useState(false);
    const [hasError, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    return (
        <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center" className={classes.root}>
            <Grid item xs={12} sx={{minHeight: '100vh', height: '100%'}}>
                <Grid
                    sx={{marginTop: "16px", height: '100%', p: matchDownSM ? 0 : '0 200px'}}
                    container
                    direction="column"
                    alignItems={matchDownSM ? 'center' : 'flex-start'}
                    spacing={matchDownSM ? 5 : 6}
                    justifyContent="space-between"
                >
                  
                    <Grid item xs={12} container direction="column">
                        <Card className={classes.card}>
                            <CardContent className={classes.content}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                        >
                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item container direction="column">
                                                        {!loading && !payOk && <Grid item>
                                                            <Typography
                                                                color={customTheme.dark}
                                                                gutterBottom
                                                                variant={matchDownSM ? 'h4' : 'h3'}
                                                                className={classes.bill}
                                                            >
                                                                OTP Verification
                                                            </Typography>

                                                            <Typography
                                                                color={customTheme.dark}
                                                                gutterBottom
                                                                variant="p"
                                                                mt={1}
                                                            >
                                                                {
                                                                    location.state.message?? " A One Time Password was sent to your email/phone to proceed with the deposit."
                                                                }
                    
                       
                                                            </Typography>
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <OTPForm loading={loading} setLoading={setLoading} hasError={hasError} setError={setError} payOk={payOk} setPayOk={setPayOk} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {!loading && <Box mt={4} textAlign="center">
                            <Button
                                startIcon={<ArrowBack  />}
                                style={{marginRight: '1rem', color: "666666", fontWeight: '400', fontSize: '15px', fontStyle: 'normal' }}
                                onClick = {() => {
                                    history.push(RoutePaths.dashboard);
                                }}
                
                            >
                                Dashboard
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VerifyPaymentOTP;

