import { makeStyles } from "@material-ui/core";


const rgba = (r, g, b, a) => {
    return `rgba(${r}, ${g}, ${b}, ${a})`
}
const theme = {
    primary: rgba(22, 105, 247, 0.1),
    secondary: rgba(190, 46, 221, 0.1),
    success: "rgba(34, 176, 46, 0.1)",
    success2: rgba(106, 176, 76, 1),
    danger: rgba(229, 92, 70, 0.1),
    info: rgba(62, 42, 167, 0.1),
    warning: rgba(255, 193, 7, 0.1),
    textPrimary: rgba(22, 105, 247, 1),
    textDanger: rgba(229, 92, 70, 1),
    textSecondary: rgba(190, 46, 221, 1),
    textSuccess: rgba(34, 176, 46, 1),
    textWarning: rgba(255, 193, 7, 1),
    textInfo: rgba(62, 42, 167, 1),
    yellow: '#FFC107',
    purple: '#BE2EDD',
    light: '#FFFFFF',
    dark: "#234",
    transparent: 'transparent',
    background: "#F4F5F7",
    black: "#000000",
    darkBlue: "#234",
    gray: "#b0b0b0",
    lightGray: "#FBFBFB",
    skyBlue: "#19C0FF",
    green: 'green',
    darkGradient: "linear-gradient(267.21deg, #171719 2.21%, #303030 97.79%)"
}

const customTheme = theme;

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '16px !important'
    },
    avatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.largeAvatar,
        backgroundColor: customTheme.transparent,
        color: '#fff'
    },
    primary: {
        backgroundColor: customTheme.primary + " !important"
    },
    secondary: {
        backgroundColor: customTheme.secondary + " !important"
    },
    success: {
        backgroundColor: customTheme.success + " !important"
    },
    danger: {
        backgroundColor: customTheme.danger + " !important"
    },
    info: {
        backgroundColor: customTheme.info + " !important"
    },
    warning: {
        backgroundColor: customTheme.warning + " !important"
    },
    padding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    textInfo: {
        color: customTheme.textInfo + " !important"
    },
    textPrimary: {
        color: customTheme.textPrimary + " !important"
    },
    textSecondary: {
        color: customTheme.textSecondary + " !important"
    },
    textDanger: {
        color: customTheme.textDanger + " !important"
    },
    textSuccess: {
        color: customTheme.textSuccess + " !important"
    },
    textWarning: {
        color: customTheme.textWarning + " !important"
    },
    textLight: {
        color: customTheme.light + " !important"
    },
    bgDark: {
        background: customTheme.darkGradient + " !important"
    }
}));


export {theme, rgba, useStyles};