import React from 'react';
import {Avatar, makeStyles, Typography} from '@material-ui/core';
import { theme as customTheme, rgba } from '../../../constants';
import { IconUser } from '@tabler/icons';

import BlankUser from '../../../assets/images/blank_user.png';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { RoutePaths } from '../../../routes/RoutePaths';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    userCard: {
        backgroundColor: customTheme.darkBlue,
        color: customTheme.dark,
        overflow: 'hidden',
        position: 'relative',
        padding: "20px",
        borderRadius: "12px",
        display: "flex",
        marginTop: "5rem",
        marginBottom: "10px",
        cursor: "pointer"
    },
    heading: {
        color: customTheme.light,
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "5px"
    },
    tagLine: {
        fontWeight: 400,
        color: rgba(255, 255, 255, 0.5),
        fontSize: "12px"
    },
    userIcon: {
        background: rgba(255, 255, 255, 0.1),
        color: customTheme.light
    },
    profilePicture: {
        borderRadius: "50px",
        background: customTheme.light
    },
    item1: {
        flex: 1
    },
    item2: {
        flex: 2
    }
}));

const UserProfileCard = () => {
    const classes = useStyles();
    const userDetail = useSelector(state => state.account.user);

    const history = useHistory();

    return (
        <div className={classes.userCard} onClick={() => history.push(RoutePaths.account)}>
            <div className={classes.item1}>
                <Avatar variant="rounded" className={classes.profilePicture}>
                    <img src={userDetail?.avatar?? BlankUser} alt="Avatar" width="20px" />
                </Avatar>
            </div>
            <div className={classes.item2}>
                <Typography variant="h4" className={classes.heading}>
                    {_.truncate(userDetail?.user_data?.full_name, {length: 10})}
                </Typography>
                <Typography variant="subtitle2" className={classes.tagLine}>
                    {_.capitalize(userDetail?.user_type?.type)}
                </Typography>
            </div>
            <div className={classes.item1}>
                <Avatar variant="rounded" className={classes.userIcon}>
                    <IconUser stroke={1.5} size="1.2rem" />
                </Avatar>
            </div>
        </div>
    );
};

export default UserProfileCard;
