import axios from 'axios';
import { app } from '../config';
import { store } from '../store/configureStore';

const httpClient = () => {
    const instance = axios.create({
        baseURL: app.server.apiBaseUrl
    });

    const token = store.getState().account.token;

    if (token)
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return instance;
};

export const postData = async (url, data, success = null, error = null, expired = null) => {
    await api_call(url, "post", data, success, error, expired);
}

export const getData = async (url, data, success = null, error = null, expired = null) => {
    await api_call(url, "get", {params: data}, success, error, expired);
}

export const api_call = async (url, method, data, success, error) => {    
    await httpClient()[method](url, data).then(res => {
        if (success) 
            success(res.data);
    })
    .catch(err => {
        if (error) {
            if (err?.response?.data) {
                error(err.response.data)
            }
            else {
                error(err)
            }   
        }
    });
}