import {REGISTER} from './actions';

const initialState = {
    completed: false,
    data: {},
    otpSent: false,
    nextRoute: null
}
const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default registrationReducer;
