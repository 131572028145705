const App = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs,  like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    theme: 'light',
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    server: {
        apiBaseUrl: process.env.REACT_APP_API_URL,
        apiKey: process.env.REACT_APP_API_KEY,
        company: process.env.REACT_APP_COMPANY
    },
    fw: {
        pk: process.env.REACT_APP_FLW_PK
    }
};

export default App;

