import {combineReducers} from 'redux';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import accountReducer from './accountReducer';
import walletFundingReducer from './walletFundingReducer';
import appReducer from './appReducer';
import registrationReducer from './registrationReducer';
import { LOGOUT, RESET_APP } from './actions';
import transactionReducer from './transactionReducer';


const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    account: accountReducer,
    app: appReducer,
    registration: registrationReducer,
    transaction: transactionReducer,
    walletFunding: walletFundingReducer
});


const rootReducer = (state, action) => {
    if (action.type === RESET_APP || action.type === LOGOUT) {
      state = undefined;
    }
  
    return reducer(state, action);
}


export default rootReducer;
